import { graphql } from 'gatsby';
import _has from 'lodash/has';
import React from 'react';

import ContentBreadcrumb from '../content/ContentBreadcrumb/ContentBreadcrumb';
import FieldSections from '../field/FieldSections/FieldSections';
import FieldWidgets from '../field/FieldWidgets/FieldWidgets';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import './news.scss';
import './page.scss';

const NewsPage = ({ data, pageContext }) => {
    const page = data.nodeNews;
    const {
        breadcrumb: { crumbs },
    } = pageContext;

    // Because news nodes only use one type of component layout gatsby doesn't set
    // a __typename, making it impossible for FieldSections to know the section
    // type. We add it manually here.
    if (_has(page, 'r.sections') && page.r.sections.length) {
        page.r.sections.forEach((section) => {
            section['__typename'] = 'paragraph__columns_two_asym_right';
        });

        // Remove the first section so we can render the title inside it.
        if (page.r.sections[0].r.col1) {
            page.col1 = page.r.sections[0].r.col1;
        }
        if (page.r.sections[0].r.col1) {
            page.col2 = page.r.sections[0].r.col2;
        }

        page.r.sections.shift();
    }

    return (
        <Layout>
            <ContentBreadcrumb crumbs={crumbs} label={page.title} />
            <div className="page__content">
                <Seo title={page.title} />
                <div className="container">
                    <div className="container-content">
                        <div className="row">
                            <div className="col-md-8">
                                <header className="news--header">
                                    <h1>{page.title}</h1>
                                    <time
                                        className="news--date"
                                        dateTime={page.date}>
                                        {page.date_formatted}
                                    </time>
                                </header>
                                <div className="news--content">
                                    {page.col1 && (
                                        <FieldWidgets widgets={page.col1} />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                {page.col2 && (
                                    <FieldWidgets widgets={page.col2} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {page.r && page.r.sections && (
                    <FieldSections sections={page.r.sections} />
                )}
            </div>
        </Layout>
    );
};

export default NewsPage;

export const query = graphql`
    query ($id: String!) {
        nodeNews(id: { eq: $id }) {
            ...nodeNewsFragment
        }
    }
`;
